import { SettingsService } from "../SettingsService";
import axiosWrapper from "../AxiosWrapper";
import {ApiClientUsageDto} from "../CvdbContracts/Api";


class ApiClientUsageService{


    static getApiClientUsage = async(customerId: string, from: Date, to: Date) => {

        let response = await axiosWrapper.getAuthenticatedInstance().get(SettingsService.settings.cvbdBackendBaseUrl + `api/customer-accounts/${customerId}/api-client-usage?from=${encodeURIComponent(from.toISOString())}&to=${encodeURIComponent(to.toISOString())}`);

        let usage: Array<ApiClientUsageDto> = response.data;
        return usage;
    }
};

export default ApiClientUsageService;