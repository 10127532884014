import Meta from '../components/Meta'

const Services = () => {
  // page content
  const pageTitle = 'Våra tjänster'

  return (
    <div className="page-container">
      <Meta title={pageTitle}/>
      <h3>{pageTitle}</h3>
    </div>
  )
}

export default Services