import { SettingsService } from "../SettingsService";
import axiosWrapper from "../AxiosWrapper";
import { CustomerAccountDto } from "../CvdbContracts/Api";

class CustomerAccountsService{

    static createInitialCustomer : any = async(userId: string) => {

        await axiosWrapper.getAuthenticatedInstance()({
            method: 'post',
            url: SettingsService.settings.cvbdBackendBaseUrl + "api/customer-accounts/create-initial-account",
            data: JSON.stringify({accountOwnerUserId: userId})
          });
        }

    static getCustomerAccounts = async() => {

        let response = await axiosWrapper.getAuthenticatedInstance()({
            method: 'get',
            url: SettingsService.settings.cvbdBackendBaseUrl + `api/customer-accounts`,
          });

        let accounts: Array<CustomerAccountDto> = response.data;
        return accounts;
    }
    

};

export default CustomerAccountsService;