import Meta from '../components/Meta'

const Home = () => {
    // page content
    const pageTitle = 'CVDB'

    return (
        <div className='page-container'>
          <Meta title={pageTitle}/>
          <h3>{pageTitle}</h3>
        </div>
    )
}

export default Home