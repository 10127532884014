import create from "zustand";
import SelectAccount from "../../components/SelectAccount";
import {useUserStore} from "./UserStore";


const userStore = create(useUserStore);

const UserHandler = ({ children }: any) => {

    userStore((state) => state.initialize());

    const mustSelectCustomer = userStore((state) => state.mustSelectCustomer);
    
    return (
        <>
            <SelectAccount show={mustSelectCustomer}></SelectAccount>
            {children}
        </>
      );}

export default UserHandler;
export {userStore}