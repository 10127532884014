import { Settings } from "../Settings";
import devEnvSettings from "../azure-environment.json";


export class SettingsService {

    public static settings: Settings;

    public static getSettings = async() => {
        if(process.env.NODE_ENV === 'production'){
            var response = await fetch('/azure-environment.json');
            var envSettings = await response.json();
            this.settings = new Settings().createSettingsObject(envSettings.Environment.CurrentEnvironment);;

        }else{
            console.log("Loading dev settings...")
            this.settings = new Settings().createSettingsObject(devEnvSettings.Environment.CurrentEnvironment);;
        }
    }
}