import { NavLink, useLocation } from "react-router-dom";
import { Nav, Container } from "react-bootstrap";
import { userStore } from "../contexts/User/UserHandler";
import cvdb_logo from '../images/cvbd_logo_white.png';

const Menu = () => {

  const setMustSelectCustomer = userStore((state) => state.setMustSelectCustomer)
  const isAuthenticated = userStore((state) => state.isAuthenticated)
  const isAdministrator = userStore((state) => state.user.is_administrator)
  const logout = userStore((state) => state.logout)
  const location = useLocation();

  let extraMenu;
  let activeClassName = "nav-link px-2 menu-link-active mt-2";
  let inActiveClassName = "nav-link px-2 menu-link-inactive mt-2";
  let menuButtonClassName = "nav-link px-2 menu-link-inactive mt-1";

  const isUserAccountView = () => {
    if (location.pathname === '/my-account/api-keys') {
      return true;
    }
    return false;
  }

  if(isAuthenticated){
    extraMenu = <>
    <li style={{marginLeft: 100}}>
      <NavLink to="/my-account/dashboard" className={({ isActive }) => isActive || isUserAccountView() ? activeClassName : inActiveClassName}>
            Mitt konto
      </NavLink>
    </li>
    <li>
      <NavLink to="/" className={menuButtonClassName}>
        <button type="button" className="btn btn-primary me-2" onClick={logout}>
            Logga ut
        </button>
        </NavLink>
    </li>
    { isAdministrator &&
    <li>
      <NavLink to={location.pathname} className={menuButtonClassName}>
        <button type="button" className="btn btn-primary me-2" onClick={() => setMustSelectCustomer(true)}>
            Ändra kund
        </button>
        </NavLink>
    </li>
    }
  </>
  }
  else{
    extraMenu = <>
            <li style={{marginLeft: 100}}>
              <NavLink to="/sign-up" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                Registrera dig
              </NavLink>
            </li>
            <li>
            <NavLink to="/sign-in" className={menuButtonClassName}>
            <button type="button" className="btn btn-primary me-2">
                  Logga in
              </button>
            </NavLink>
          </li>
  </>
  }


  return (
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 border-bottom" style={{backgroundColor: '#26374D'}}>
        <NavLink 
          to="/"
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-light text-decoration-none"
        >
          <img src={cvdb_logo} alt="" height={29} width={150} style={{marginLeft:20}} />
        </NavLink>
        <Nav>
          <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <li>
              <NavLink to="/services" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                Våra tjänster
              </NavLink>
            </li>
            <li>
              <NavLink to="/pricing" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                Priser
              </NavLink>
            </li>
            <li>
              <NavLink to="/documentation" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                Dokumentation
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                Om oss
              </NavLink>
            </li>
            {extraMenu}
          </ul>

            {/* <div className="col-md-3 text-end">
                <Link to="/">
                <button type="button" className="btn btn-outline-primary me-2">
                    Inlogg
                </button>
                </Link>
            </div> */}
        </Nav>
      </header>
    </Container>
  );
};

export default Menu;
