// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

// Layout
import Layout from "./layout/Layout";

// pages
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Documentation from "./pages/Documentation";
import Services from "./pages/Services";
import Pricing from "./pages/Pricing";
import SignUp from "./pages/SignUp";
import SignupCallback from "./pages/SignupCallback";
import SignIn from "./pages/SignIn";
import SigninCallback from "./pages/SigninCallback";
import MyAccount from "./pages/Authenticated/MyAccount";
import ApiKeys from "./pages/Authenticated/ApiKeys";
import Dashboard from "./pages/Authenticated/Dashboard";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Logs from "./pages/Authenticated/Logs";

library.add(faCopy)

const App = () => {

  return (
    <Layout>
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/my-account" element={<MyAccount/>} >
            <Route index element={<Dashboard/>} />
            <Route path="dashboard" element={<Dashboard/>} />
            <Route path="api-keys" element={<ApiKeys/>} />
            <Route path="logs" element={<Logs/>} />
          </Route>
          <Route path="/signin-callback" element={<SigninCallback />} />
          <Route path="/signup-callback" element={<SignupCallback />} />
          <Route element={<NotFound />} />
        </Routes>
      </Container>
    </Layout>
  );
};

export default App;
