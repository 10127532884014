export class AccessToken{

    public constructor(token: string, tokenExpirationTime: number){
        this.token = token;
        this.tokenExpirationTime = tokenExpirationTime;
    }

    private token: string;
    private tokenExpirationTime: number

    public isValid() {
        return this.tokenExpirationTime > Date.now();
    }

    public getToken = () => this.token;
    public getTokenExpirationTime = () => this.tokenExpirationTime;
    
}