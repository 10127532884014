// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { createRoot } from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.css";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SettingsService } from "./services/SettingsService";
import ErrorHandler from './contexts/Error/ErrorHandler';
import UserHandler from './contexts/User/UserHandler';

SettingsService.getSettings().then(
  () => {
    const container = document.getElementById('root');
    const root = createRoot(container!);
    
    root.render(
        <BrowserRouter>
        <UserHandler>
          <ErrorHandler>
              <App />
            </ErrorHandler>
          </UserHandler>
        </BrowserRouter>
    );
    
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
    
  }
);

