import { Container } from 'react-bootstrap'

const Header = ({ head, description }: any) => {
  return (
    <Container>
      <div className='starter-template text-center'>
        
        <p className='lead'>{description}</p>
      </div>
    </Container>
  )
}

export default Header
