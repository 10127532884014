import Meta from '../components/Meta'

const About = () => {
  // page content
  const pageTitle = 'Om oss'

  return (
    <div className='page-container'>
      <Meta title={pageTitle}/>
      <h3>Om Conperio</h3>
    </div>
  )
}

export default About