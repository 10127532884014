import { SettingsService } from "../SettingsService";
import axiosWrapper from "../AxiosWrapper";
import { TableLogEntry } from "../CvdbContracts/Api";

class LogReaderService{

    static getLogs : any = async() => {

        let response = await axiosWrapper.getAuthenticatedInstance()({
            method: 'get',
            url: SettingsService.settings.cvbdBackendBaseUrl + `api/log`,
          });

        let logs: Array<string> = response.data;
        return logs;
        }

    static getLogEnteries = async(log_name: string, from_date: Date, to_date: Date, messageFilter: string | null) => {

        let response = await axiosWrapper.getAuthenticatedInstance()({
            method: 'get',
            url: SettingsService.settings.cvbdBackendBaseUrl + `api/log/${log_name}?from=${from_date.toISOString()}&to=${to_date.toISOString()}&messageFilter=${messageFilter??''}`,
          });

        let logEnteries: Array<TableLogEntry> = response.data;
        return logEnteries;
    }
    

};

export default LogReaderService;