import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import useErrorStore from "../contexts/Error/ErrorStore";
import { useUserStore } from "../contexts/User/UserStore";
import { AccessTokenService } from "./Auth/AccessTokenService";
import { IErrorResponse } from "./Dtos/IErrorResponse";

const instance = axios.create({
    headers:{
        "Content-Type": "application/json;charset=utf-8",
        "Accept": "application/json, application/problem+json",
    }
})

const logout = useUserStore.getState().logout;

const authenticatedInstance = axios.create({
    headers:{
        "Content-Type": "application/json;charset=utf-8",
        "Accept": "application/json, application/problem+json",
    }
})

const errorStore = useErrorStore;

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: any): boolean => {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

const handleDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === "object") handleDates(value);
  }
}

const beforeRequestInterceptor = async (request: AxiosRequestConfig) => {
    try {
        var token = await AccessTokenService.getAccessToken();
        request.headers = {...request.headers, "Authorization": token.getToken()};                    
    } catch (error) {
        console.log(error);
    }
    return request;
    }

const responseInterceptor = (response: AxiosResponse) => {
    handleDates(response.data);
    return response;
}

const addError = (title: string, description: Array<string>) => {
    let currentState = errorStore.getState();
    currentState.addError(title, description);
    errorStore.setState(currentState);
}

const errorInterceptor = (error: AxiosError) => {
    if(error.response?.status === 401){
        addError( 'Utloggad', ['Du har blivit utloggad på grund av inaktivitet'] );
        logout();
    }
    else{
        let parsedError = error.response?.data as IErrorResponse;
        if(parsedError){
            let details: Array<string> = parsedError.detail !== undefined ? [parsedError.detail] : [];
            if(parsedError.errors){
                for(const [key, value] of Object.entries(parsedError.errors)){
                    let detailString = key + ': ';
                    value.forEach((e: string) => {
                        detailString += e + ' ';
                    });
                    details.push(detailString);
                }
            }
            addError(parsedError.title, details);
        }else{
            addError('Http: ' + error.code, [error.message] );
        }
    }
    return Promise.reject(error);
}

authenticatedInstance.interceptors.request.use(beforeRequestInterceptor);
authenticatedInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
instance.interceptors.response.use(responseInterceptor, errorInterceptor);

const getAuthenticatedInstance = () => {
    return authenticatedInstance;
}
const getInstance = () => {
    return instance;
}

const axoisWrapper = {
    getInstance,
    getAuthenticatedInstance
}

export default axoisWrapper;