import { SettingsService } from "../SettingsService";
import axiosWrapper from "../AxiosWrapper";
import { CreateUserRequest, UserDto } from "../CvdbContracts/Api";

class UserAccessService{


    static createUser = async(userDto: CreateUserRequest) => {
        let instance = axiosWrapper.getInstance();

        await instance({
            method: 'post',
            url: SettingsService.settings.cvbdBackendBaseUrl + "api/user-access/users",
            data: JSON.stringify(userDto)
          });
    }

    static getCurrentUser = async () => {
        let instance = axiosWrapper.getAuthenticatedInstance();
            let response = await instance({
                method: 'get',
                url: SettingsService.settings.cvbdBackendBaseUrl + "api/user-access/users/me",
              });
    
            let user: UserDto = response.data;
            return user;
    }


};

export default UserAccessService;