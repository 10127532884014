import create from "zustand/vanilla";
import { AccessTokenService } from "../../services/Auth/AccessTokenService";
import CustomerAccountsService from "../../services/CustomerAccounts/CustomerAccountsService";
import { CustomerAccountDto } from "../../services/CvdbContracts/Api";
import UserAccessService from "../../services/UserAccess/UserAccessService";

interface User{
    firstName: string;
    lastName: string;
    email: string;
    is_authenticated: boolean;
    is_administrator: boolean;
}

interface UserStore{
    user: User;
    isAuthenticated: boolean,
    customerId: string;
    customerName: string;
    mustSelectCustomer: boolean,
    login: (access_token: string, expires_in: number) => void;
    logout: () => void;
    setUser: (firstName: string, lastName: string, email: string, is_administrator: boolean) => void;
    setCustomer: (customerId: string, customerName: string) => void;
    setAuthenticated: (authenticated: boolean) => void;
    setMustSelectCustomer: (select: boolean) => void;
    initialize: () => void;
}

const useUserStore = create<UserStore>((set, get) => ({
    user: {firstName: '', lastName: '', email: '', is_authenticated: false, is_administrator: false, currentCustomerId: '', currentCustomerName: ''},
    isAuthenticated: false,
    customerId: '',
    customerName: '',
    mustSelectCustomer: false,
    login: (acces_token, expires_in) => {
        AccessTokenService.setAccessToken(acces_token, expires_in);
        set({isAuthenticated: true});
    },
    logout: () => {
        AccessTokenService.deleteAccessToken();
        set({isAuthenticated: false});
    },
    setUser: (firstName, lastName, email, is_administrator) => {
        let currentUser = get().user;
        set({user: {...currentUser, firstName: firstName, lastName: lastName, email: email, is_administrator: is_administrator}});
    },
    setCustomer: (customerId, customerName) => {
        set({customerId: customerId, customerName: customerName});
        localStorage.setItem('currentCustomer', customerId);
    },
    setAuthenticated: (authenticated) => {
        set({isAuthenticated: authenticated})
    },
    setMustSelectCustomer: (select) => {
        set({mustSelectCustomer: select})
    },
    initialize: () => {
        let currentState = get();
        if(AccessTokenService.hasValidToken() && !currentState.isAuthenticated){
            set({isAuthenticated: true});
        }else if(!currentState.isAuthenticated){
          AccessTokenService.getAccessToken().then((token) => {
            set({isAuthenticated: true});
        }, (error) => {
            console.log(error);
          });
        }
        if(currentState.user.email === '' && currentState.isAuthenticated){
            UserAccessService.getCurrentUser().then( user => {
                if(user === undefined){return;}
                CustomerAccountsService.getCustomerAccounts().then( accounts => {
                  var customerAccounts = accounts as Array<CustomerAccountDto>;
                  if(customerAccounts.length === 1){
                    set({customerId: accounts[0].id, customerName: accounts[0].name!, user: {...currentState.user, firstName: user.firstName ?? '', lastName: user.lastName ?? '', email: user.email ?? '', is_administrator: user.isAdministrator  }})
                  }
                  else if (customerAccounts.length > 1){
                    let selectedCustomer = localStorage.getItem('currentCustomer');
                    let currentCustomer = customerAccounts.find(a => a.id === selectedCustomer);
                    if(currentCustomer){
                        set({customerId: currentCustomer.id, customerName: currentCustomer.name, user: {...currentState.user, firstName: user.firstName ?? '', lastName: user.lastName ?? '', email: user.email ?? '', is_administrator: user.isAdministrator }})
                    }
                    else if(!currentState.mustSelectCustomer){
                        set({mustSelectCustomer: true})
                    }
                  }
                });
              });
            }

    }
}));

export {useUserStore};
