import { useEffect } from "react";
import { userStore } from "../contexts/User/UserHandler";
import { useNavigate } from "react-router-dom";
import UserAccessService from "../services/UserAccess/UserAccessService";
import CustomerAccountsService from "../services/CustomerAccounts/CustomerAccountsService";
import { CustomerAccountDto } from "../services/CvdbContracts/Api";

const SigninCallback = () => {

    const navigate = useNavigate();

    const login = userStore((state) => state.login);
    const setCustomer = userStore((state) => state.setCustomer);
    const isAuthenticated = userStore((state) => state.isAuthenticated);
    
    useEffect(() => {

        var userState = userStore.getState();
        if(window.location.pathname !== '/signin-callback'){
            return;
        }
        let query = new URLSearchParams(window.location.hash.substring(1));

        if(!userState.isAuthenticated){
            var acces_token = query.get('id_token') ?? '';
            var expires_in = Number.parseInt( query.get('expires_in') ?? '');

            login(acces_token, expires_in);
            UserAccessService.getCurrentUser().then( user => {

                CustomerAccountsService.getCustomerAccounts().then( accounts => {
                    var customerAccounts = accounts as Array<CustomerAccountDto>;
                    if (customerAccounts.length === 1){
                        setCustomer(customerAccounts[0].id!, customerAccounts[0].name ?? 'Unknown');
                    }
                    if (customerAccounts.length > 1){
                        // Account chooser
                    }
                    navigate('/my-account');      
                });
              });
    }
    }, [navigate,login,setCustomer]);    


    return (
        <div>
            Authenticerad {isAuthenticated}
        </div>
    )
}

export default SigninCallback;