import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Header from '../components/Header'
import Meta from '../components/Meta'
import { Auth0Service } from '../services/Auth/Auth0Service'
import { Login } from '../services/Auth/Dtos/AuthRequests'


const SingIn = () =>{
  // page content
  const pageTitle = 'Logga in'
  const pageDescription = ''

  const [isBusy, setBusy] = useState<boolean>(false);
  const [loginRequest, setLoginRequest] = useState<Login>({} as Login);

  const handleSubmit = async (event: any) => {
    setBusy(true);
    event.preventDefault();

    await Auth0Service.login(loginRequest);

    setBusy(false);

  };
    return (
        <div>
          <Meta title={pageTitle}/>
          <Header head={pageTitle} description={pageDescription} />
          <Form onSubmit={async (e) => await handleSubmit(e)}>
            <fieldset disabled = {isBusy}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>E-postadress</Form.Label>
                <Form.Control required type="email" autoComplete='username' placeholder="Ange din e-postadress" onChange={ e => setLoginRequest({...loginRequest, username: e.target.value} ) }/>
              </Form.Group>
    
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Lösenord</Form.Label>
                <Form.Control required type="password" autoComplete='new-password' placeholder="Ange lösenord" onChange={ e => setLoginRequest({...loginRequest, password: e.target.value} ) }/>
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </fieldset>
        </Form>
    
    
        </div>
      )
}

export default SingIn;