import { useEffect } from "react";
import { userStore } from "../contexts/User/UserHandler";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import UserAccessService from "../services/UserAccess/UserAccessService";
import CustomerAccountsService from "../services/CustomerAccounts/CustomerAccountsService";

const SignupCallback = () => {

    const navigate = useNavigate();

    useEffect(() => {
      let userState = userStore.getState();
      if(window.location.pathname !== '/signup-callback'){
        return;
      }

      const createInitialData = async() => {

        var currentUser = await UserAccessService.getCurrentUser();
          await CustomerAccountsService.createInitialCustomer(currentUser?.id);
          navigate('/my-account');  
      };

      let query = new URLSearchParams(window.location.hash.substring(1));

      if(!userState.isAuthenticated){
        var acces_token = query.get('id_token') ?? '';
        var expires_in = Number.parseInt( query.get('expires_in') ?? '');

        userState.login(acces_token, expires_in);
        userStore.setState({...userState});

        createInitialData().catch(console.error);
    }
    }, [navigate]);


    return (
        <div>
          <Spinner animation="border"></Spinner>
        </div>
    )
}

export default SignupCallback;