import { Container } from 'react-bootstrap';
import { NavLink, Outlet } from 'react-router-dom';
import Meta from '../../components/Meta'
import { userStore } from "../../contexts/User/UserHandler";


const MyAccount = () => {
  // page content
  const pageTitle = 'Mitt konto'

  let adminMenu;
  let activeClassName = "nav-link px-2 sub-nav-link-primary mt-2";
  let inActiveClassName = "nav-link px-2 sub-nav-link-secondary mt-2";

  const isAdministrator = userStore((state) => state.user.is_administrator);

  if(isAdministrator){
    adminMenu = <>
              <div style={{float:'left', marginLeft: 30}}>
                <NavLink to="logs" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                  <span>Loggar</span>
                </NavLink>
              </div>
</>
  }

  return (
    <div>
      <Meta title={pageTitle} />
      {/* <Header head={welcomeText} /> */}
      <Container>
        <div className='row pt-2 pb-2' style={{ backgroundColor: '#dddddd' }}>
          <div className='col-12'>
            <div style={{float:'left'}}>
              <NavLink to="dashboard" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                <span>Dashboard</span> 
              </NavLink>
            </div>
            <div style={{float:'left', marginLeft: 30}}>
              <NavLink to="api-keys" className={({ isActive }) => isActive ? activeClassName : inActiveClassName}>
                <span>Api nycklar</span>
              </NavLink>
            </div>
            {adminMenu}
          </div>
        </div>
        <div className="row page-container">
          <div className="col-12">
            <Outlet />
          </div>
        </div>
      </Container >
    </div>

  )
}

export default MyAccount;
