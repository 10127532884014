import logo from '../images/logo.png';

const Footer = () => {
    const year = new Date().getFullYear()
    return (
      <>
        <footer className='text-center'>
          Copyright Conperio <img src={logo} alt="" height={30} width={30} /> &copy; {year}
        </footer>
      </>
    )
  }
  
  export default Footer
  