import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { userStore } from "../../contexts/User/UserHandler";
import ApiClientUsageService from "../../services/ApiClientUsage/ApiClientUsageService";

import "react-datepicker/dist/react-datepicker.css";
import { Col, Container, Row } from "react-bootstrap";
import { ApiClientUsageDto } from "../../services/CvdbContracts/Api";

const getTicks = (startDate: Date, endDate: Date, data: Array<ApiClientUsageDto>) => {
  const diffDays = moment(endDate).diff(startDate, 'days');
  const ticks: any[] = [];

  const dayInMilliseconds = 1000*60*60*24;

  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  let startTime = startDate.getTime();

  for (let i = 0; i <= diffDays; i++) {
    let startTimeKey = startTime + i * dayInMilliseconds;
    let endTimeKey = startTimeKey + dayInMilliseconds;
    let usages = data.filter(e => new Date(e.timestamp!).getTime() >= startTimeKey && new Date(e.timestamp!).getTime() < endTimeKey).length;
    ticks.push({timestamp: startTimeKey, value: usages});
  }
  return ticks;
};

const dateFormatter = (date :number) => {
  return moment(date).format("DD/MM");
};

const Dashboard = () =>{
  
  const from = moment().add(-1, 'M');
  const to = new Date();

  const [ticks, setTicks] = useState<Array<any>>([])
  const [fromDate, setFromDate] = useState<Date>(from.toDate())
  const [toDate, setToDate] = useState<Date>(to)
  


  const customerId = userStore((state) => state.customerId);


  useEffect(() => {
    const loadApiUsage = async () => {
      let apiUsage = await ApiClientUsageService.getApiClientUsage(customerId, fromDate, toDate);
      let ticks = getTicks(fromDate, toDate, apiUsage)
      setTicks(ticks);
    }
    if(customerId){
      loadApiUsage();
    }
  }, [fromDate, toDate, customerId]);

  const style = {
    padding: 6,
    backgroundColor: "#fff",
    border: "1px solid #ccc"
  };

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active) {
      const currData = payload && payload.length ? payload[0].payload : null;
      return (
        <div className="area-chart-tooltip" style={style}>
          <p>
            {currData ? moment(currData.timestamp).format("yyyy-MM-DD") : "--"}
          </p>
          <p>
            {"Api anrop: "}
            <em>{currData ? currData.value : " -- "}</em>
          </p>
        </div>
      );
    }
  
    return null;
  };

  // page content
  return (
      <div>
        <h3>Dashboard</h3>

        <Container>
          <Row>
            <Col><h4>Api anrop</h4></Col>
          </Row>
          <Row>
            <Col>Från<DatePicker className="form-control" selected={fromDate} onChange={(date:Date) => setFromDate(date)} /></Col>
            <Col>Till<DatePicker className="form-control" selected={toDate} onChange={(date:Date) => setToDate(date)} /></Col>
          </Row>
          <Row>
            <Col>
            <ResponsiveContainer width="90%" height={200}>
        <AreaChart
  width={730}
  height={450}
  data={ticks}
  margin={{
    top: 20, right: 20, bottom: 20, left: 20,
  }}
>
  <XAxis dataKey="timestamp" tickFormatter={dateFormatter} />
  <YAxis />
  <Area dataKey="value" stroke="#8884d8" fill="#8884d8" />
  <Tooltip content={<CustomTooltip />}/>
</AreaChart>
  </ResponsiveContainer>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default Dashboard;