import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Header from '../components/Header'
import Meta from '../components/Meta'
import UserAccessService from '../services/UserAccess/UserAccessService'
import { Auth0Service } from '../services/Auth/Auth0Service'
import { CreateUserRequest } from '../services/CvdbContracts/Api'


const SignUp = () => {
  // page content
  const pageTitle = 'Registrering'
  const pageDescription = ''

  const [isBusy, setBusy] = useState<boolean>(false);
  const [createUserRequest, setCreateUserRequest] = useState<CreateUserRequest>({} as CreateUserRequest);
  
  const handleSubmit = async (event: any) => {
    setBusy(true);
    event.preventDefault();

    try {
      await UserAccessService.createUser(createUserRequest);
      // Get accesstoken
      authenticate(createUserRequest.email, createUserRequest.password);
        
    } catch (error) {
      
    }

    setBusy(false);

  };

  const authenticate = (username: string, password: string) => {
    Auth0Service.loginSignedUp({username: username, password: password});
  }

  return (
    <div>
      <Meta title={pageTitle}/>
      <Header head={pageTitle} description={pageDescription} />
      <Form onSubmit={async (e) => await handleSubmit(e)}>
        <fieldset disabled = {isBusy}>
          <Form.Group className="mb-3">
            <Form.Label>Förnamn</Form.Label>
            <Form.Control required type="text" placeholder="Ange ditt förnamn" onChange={ e => setCreateUserRequest({...createUserRequest, firstName: e.target.value} ) }/>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Efternamn</Form.Label>
            <Form.Control required type="text"  placeholder="Ange ditt efternamn" onChange={ e => setCreateUserRequest({...createUserRequest, lastName: e.target.value} ) }/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>E-postadress</Form.Label>
            <Form.Control required type="email" autoComplete='username' placeholder="Ange din e-postadress" onChange={ e => setCreateUserRequest({...createUserRequest, email: e.target.value} ) }/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control required type="password" autoComplete='new-password' placeholder="Ange önskat lösenord" onChange={ e => setCreateUserRequest({...createUserRequest, password: e.target.value} ) }/>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </fieldset>
    </Form>


    </div>
  )
}

export default SignUp