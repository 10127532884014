import { Toast, ToastContainer } from "react-bootstrap";
import create from "zustand"
import { IError } from "./IError"

import useErrorStore from "./ErrorStore";

const theErrorStore = create(useErrorStore);

const ErrorHandler = ({ children }: any) => {
    
    const errorStore = theErrorStore();

    const dismissToast= (error: IError) =>{
        errorStore.removeError(error.key);
    }

    const descriptions = (descriptions: Array<string>) =>
    descriptions.map(e => <div key='1'>{e}</div>);

    const errorList = 
    errorStore.errors.map(e => <Toast onClose={() => dismissToast(e)} key={e.key} bg='primary'>
          <Toast.Header>
            <strong className="me-auto">{e.title}</strong>
          </Toast.Header>
          <Toast.Body>{descriptions(e.description)}</Toast.Body>           
        </Toast>)


    const errorToast =
        <ToastContainer position="middle-center" className="p-3">
            {errorList}
        </ToastContainer>

    return (
        <>
              {errorToast}
              {children}
        </>
      );}

export default ErrorHandler;
export {useErrorStore}