import { SettingsService } from "../SettingsService";
import { Login, RefreshTokenResponse } from "./Dtos/AuthRequests";
import * as auth0 from 'auth0-js';

export class Auth0Service {
    
    static authenticationClient: auth0.WebAuth | null = null;

      private static initialize = () => {
        if(this.authenticationClient == null){
            Auth0Service.authenticationClient = new auth0.WebAuth({
                clientID: SettingsService.settings.oauthSettings.clientId,
                domain: SettingsService.settings.oauthSettings.domain,
                responseType: SettingsService.settings.oauthSettings.responseType,
                audience: SettingsService.settings.oauthSettings.audience,
                redirectUri: SettingsService.settings.oauthSettings.signUpCallbackUri,
                scope: SettingsService.settings.oauthSettings.scope, 
              });    
        }
      } 

    public static login = async(login: Login) => {
        this.initialize();
        Auth0Service.authenticationClient?.login({
            ...login,
            realm: SettingsService.settings.oauthSettings.realm,
            redirectUri: SettingsService.settings.oauthSettings.signInCallbackUri
            
          }, (error) => {
            console.log(error);
          }
        ) 
   };


    public static loginSignedUp = async(login: Login) => {
        this.initialize();
        Auth0Service.authenticationClient?.login({
            ...login,
            realm: SettingsService.settings.oauthSettings.realm,
            redirectUri: SettingsService.settings.oauthSettings.signUpCallbackUri
            
        }, (error) => {
            console.log(error);
        }); 
    };

    public static refreshToken = () => {
        this.initialize();
        return new Promise<RefreshTokenResponse|undefined>((resolve, reject) => {

            Auth0Service.authenticationClient?.checkSession({}, (err, authResult) => {
                if(err){
                    return reject(err);
                }
                return resolve({access_token: authResult.accessToken, expires_in: authResult.expiresIn, id_token: authResult.idToken});
            });    
        });
        };
}