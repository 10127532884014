import Meta from '../components/Meta'

const Pricing = () => {
    // page content
    const pageTitle = 'Priser'
    return (
        <div>
            <Meta title={pageTitle} />

            <div className="page-container">
                <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3 " style={{ borderRadius: 10 }}>
                                <h4 className="my-0 fw-normal">Prova på</h4>
                            </div>
                            <div className="card-body" style={{textAlign:'left'}}>
                            <div className="row mt-4 mb-2">
                                    <div className="col-9">Månadspris</div>
                                    <div className="col-3">0 kr</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Antal fordonsuppslag</div>
                                    <div className="col-3">10</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Pris per ytterligare uppslag</div>
                                    <div className="col-3">1 kr</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Fordonsbevakning</div>
                                    <div className="col-3">Nej</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Antal fordonsbevakningar</div>
                                    <div className="col-3">-</div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-9">Pris per ytterligare bevakning</div>
                                    <div className="col-3">-</div>
                                </div>
                                <button type="button" className="w-100 btn btn-lg btn-outline-primary">Välj</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Standard</h4>
                            </div>
                            <div className="card-body" style={{textAlign:'left'}}>
                                {/* <h1 className="card-title pricing-card-title text-center">$15<small className="text-muted fw-light">/mo</small></h1> */}
                                <div className="row mt-4 mb-2">
                                    <div className="col-9">Månadspris</div>
                                    <div className="col-3">1999 kr</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Antal fordonsuppslag</div>
                                    <div className="col-3">200</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Pris per ytterligare uppslag</div>
                                    <div className="col-3">1 kr</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Fordonsbevakning</div>
                                    <div className="col-3">Ja</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">Antal fordonsbevakningar</div>
                                    <div className="col-3">5</div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-9">Pris per ytterligare bevakning</div>
                                    <div className="col-3">500 kr</div>
                                </div>
                                <button type="button" className="w-100 btn btn-lg btn-primary">Välj</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-white bg-primary border-primary">
                                <h4 className="my-0 fw-normal">Många fordon?</h4>
                            </div>
                            <div className="card-body mt-4">
                                <div className='mb-5'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec turpis diam, condimentum id semper eget, lobortis sit amet lectus. In sodales, magna id venenatis aliquet, lorem ex sollicitudin urna, nec facilisis tortor urna non eros. Sed porta eleifend magna et ultrices. Sed auctor mollis sapien.
                                </div>
                                <button type="button" className="w-100 btn btn-lg btn-primary">Kontakta oss</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    // <div>
    //   <Meta title={pageTitle}/>
    //   <Header head={pageTitle} description={pageDescription} />
    // </div>

}

export default Pricing