export class Settings {

    environmentName: string = '';
    cvbdBackendBaseUrl: string = ''
    oauthSettings: {
        clientId: string;
        domain: string;
        audience: string;
        responseType: string;
        scope: string;
        signUpCallbackUri: string;
        signInCallbackUri: string;
        realm: string;
    } = {clientId: '', domain: '', audience: '', responseType: '', scope: '', signUpCallbackUri: '', signInCallbackUri: '', realm: ''}


    private developmentSettings = {
        environmentName: 'Development',
        cvbdBackendBaseUrl: 'https://cvdb-internal-webapi-test.azurewebsites.net/',
        oauthSettings: {
            clientId: 'SDNbAibYhhlNxiNrZFdBBjixdVfCq5co',
            domain: 'cvdb-test.eu.auth0.com',
            audience: 'https://cvdb-test.eu.auth0.com/userinfo',
            responseType: 'token id_token',
            scope: 'openid',
            signUpCallbackUri: 'http://localhost:3000/signup-callback',
            signInCallbackUri: 'http://localhost:3000/signin-callback',
            realm: 'Username-Password-Authentication'
            }
        };

    private testSettings = {
        environmentName: 'Test',
        cvbdBackendBaseUrl: 'https://cvdb-internal-webapi-test.azurewebsites.net/',
        oauthSettings: {
            clientId: 'SDNbAibYhhlNxiNrZFdBBjixdVfCq5co',
            domain: 'cvdb-test.eu.auth0.com',
            audience: 'https://cvdb-test.eu.auth0.com/userinfo',
            responseType: 'token id_token',
            scope: 'openid',
            signUpCallbackUri: 'https://nice-tree-072b30503.1.azurestaticapps.net/signup-callback',
            signInCallbackUri: 'https://nice-tree-072b30503.1.azurestaticapps.net/signin-callback',
            realm: 'Username-Password-Authentication'
        }
    };

    private productionSettings = {
        environmentName: 'Production',
        cvbdBackendBaseUrl: 'https://cvdb-internal-webapi-prod.azurewebsites.net/',
        oauthSettings: {
            clientId: 'yjB2EAzCG8N0lD1kDA3OOJi6UbY0EDZr',
            domain: 'cvdb-prod.eu.auth0.com',
            audience: 'https://cvdb-prod.eu.auth0.com/userinfo',
            responseType: 'token id_token',
            scope: 'openid',
            signUpCallbackUri: 'https://cvdbapp.com/signup-callback',
            signInCallbackUri: 'https://cvdbapp.com/signin-callback',
            realm: 'Username-Password-Authentication'
        }
    };

    public createSettingsObject(environment: string): Settings {
        switch (environment) {
            case 'Development':
                return this.createDevelopment();
            case 'Test':
                return this.createTest();
            case 'Production':
                return this.createProduction();
        }
        return this.createDevelopment();
    }

    private createDevelopment(): Settings {
        const settings = new Settings();
        return Object.assign(settings, this.developmentSettings);
    }

    private createTest(): Settings {
        const settings = new Settings();
        return Object.assign(settings, this.testSettings);
    }

    private createProduction(): Settings {
        const settings = new Settings();
        return Object.assign(settings, this.productionSettings);
    }
}