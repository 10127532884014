import { SettingsService } from "../SettingsService";
import axiosWrapper from "../AxiosWrapper";
import {ApiClientAuthorizationDto, CreateApiClientAuthorizationRequest} from "../CvdbContracts/Api";


class ApiClientAccessService{


    static createApiClientAuthorizations = async(data: CreateApiClientAuthorizationRequest, customerId: string) => {

        await axiosWrapper.getAuthenticatedInstance()({
            method: 'post',
            url: SettingsService.settings.cvbdBackendBaseUrl + `api/customer-accounts/${customerId}/api-client-authorizations`,
            data: JSON.stringify(data)
          });
    }

    static getApiClientAuthorizations = async(customerId: string) => {

        let response = await axiosWrapper.getAuthenticatedInstance().get(SettingsService.settings.cvbdBackendBaseUrl + `api/customer-accounts/${customerId}/api-client-authorizations`);

        let authorizations: Array<ApiClientAuthorizationDto> = response.data;
        return authorizations;
    }
};

export default ApiClientAccessService;