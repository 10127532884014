import moment from "moment";
import { ReactNode, useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import LogReaderService from "../../services/Logs/LogReaderService";
import { TableLogEntry } from "../../services/CvdbContracts/Api";

const Logs = () => {

    const from = moment().add(-1, 'd');
    const to = new Date();

    const [logs, setLogs] = useState<Array<ReactNode>>([]);
    const [logEnteries, setLogEnteries] = useState<Array<ReactNode>>([]);


    const [fromDate, setFromDate] = useState<Date>(from.toDate())
    const [toDate, setToDate] = useState<Date>(to)
    const [selectedLog, setSelectedLog] = useState<string>('')
    const [messageFilter, setMessageFilter] = useState<string>('')

    const selectLog = useCallback((theSelectedLog: string) => {
        LogReaderService.getLogEnteries(theSelectedLog, fromDate, toDate, messageFilter).then((le: Array<TableLogEntry>) => {
            let logEnteries = le.map(l => <tr key={l.rowKey}>
                <td>{l.timestamp?.toLocaleString()}</td>
                <td>{l.logLevel}</td>
                <td>{getLines(l.message)}</td>
            </tr>);
            setLogEnteries(logEnteries);
        })
    }, [fromDate, toDate, messageFilter]);


    useEffect(() => {
        if (logs.length !== 0) {
            return;
        }
        LogReaderService.getLogs().then((l: any) => {
            let options = l.map((ca: any) => <option key={ca} value={ca.id}>{ca}</option>);
            setLogs(options);
            selectLog(l[0]);
        })
    }, [logs.length, selectLog]);

    const getLines = (text: string | undefined) => {
        if(text === undefined){
            return;
        }
        return text.split('\n').map(l => <>{l}<br></br></>);
    }


    return (
        <div>
            <Container>

                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formSelectLog">
                            <Form.Label>Logg</Form.Label>
                            <Form.Select name="formSelectLog" aria-label="Välj log" onChange={(event) => setSelectedLog(event.target.value)}>
                                {logs}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formSelectFromDate">
                            <Form.Label>Från</Form.Label>
                            <DatePicker name="formSelectFromDate" className="form-control" dateFormat="yyyy/MM/dd hh:mm" showTimeSelect selected={fromDate} onChange={(date: Date) => setFromDate(date)} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSelectToDate">
                            <Form.Label>Till</Form.Label>
                            <DatePicker name="formSelectToDate" className="form-control" dateFormat="yyyy/MM/dd hh:mm" showTimeSelect selected={toDate} onChange={(date: Date) => setToDate(date)} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formMessageFilter">
                            <Form.Label>Filter</Form.Label>
                            <Form.Control name="formMessageFilter" type="text" placeholder="Beskrivning" onChange={e => setMessageFilter(e.target.value)} />
                        </Form.Group>
                        
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formSearchButton">
                            <Button name="formSearchButton" variant="primary" type="button" onClick={() => selectLog(selectedLog)}>
                                Sök logg
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>

                <Table striped bordered hover className="mt-5">
                    <thead>
                        <tr>
                            <th className="sticky-table-header">Datum</th>
                            <th className="sticky-table-header">LogLevel</th>
                            <th className="sticky-table-header">Meddelande</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logEnteries}
                    </tbody>
                </Table>

            </Container>


        </div>
    )
}

export default Logs;