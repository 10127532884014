import Meta from '../components/Meta'

const Documentation = () => {
  // page content
  const pageTitle = 'Dokumentation'

  return (
    <div className='page-container'>
      <Meta title={pageTitle}/>
      <h3>{pageTitle}</h3>
    </div>
  )
}

export default Documentation