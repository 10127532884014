import create from "zustand/vanilla";
import { IError } from "./IError";

interface ErrorState{
    errors: Array<IError>,
    addError: (title: string, description: Array<string>) => void,
    removeError: (key: number) => void
}

const useErrorStore = create<ErrorState>((set, get) => ({
    errors: [], 
    addError: (title, description) => set((state) => {
        state.errors.push({key: state.errors.length, title: title, description: description});
        return({...state});
    }),
    removeError: (key) => set((state) => {
        state.errors = state.errors.filter((e) => e.key !== key);
        return({...state})
    })
}));

export default useErrorStore;
