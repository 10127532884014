import { AccessToken } from "../../domain/Auth/AccessToken";
import { Auth0Service } from "./Auth0Service";

export class AccessTokenService {

    public static getAccessToken = async() => {

        var currentToken = localStorage.getItem('access_token');
        var currentTokenExpiration = localStorage.getItem('access_token_expiration_time');

        if(currentToken === null || currentTokenExpiration === null){
            throw(new Error("unauthorized"));
        }

        let currentTokenExpirationTime =  Number.parseInt(currentTokenExpiration)
        let accessToken = new AccessToken(currentToken, currentTokenExpirationTime);
        if(accessToken.isValid()){
            return accessToken;
        }

        try{
            var token = await Auth0Service.refreshToken();
            if(token !== undefined ){

                accessToken = new AccessToken('Bearer ' +  token.id_token, Date.now() + (token.expires_in * 1000) - 15000);

                localStorage.setItem('access_token', accessToken.getToken());
                localStorage.setItem('access_token_expiration_time', accessToken.getTokenExpirationTime().toString());

                return accessToken;
            }
        }
        catch(e){
            console.log(e);
            throw(new Error("unauthorized"))
        }
        throw(new Error("unauthorized"));
    }

    public static getLocalAccessToken = () => {

        var currentToken = localStorage.getItem('access_token');
        var currentTokenExpiration = localStorage.getItem('access_token_expiration_time');

        if(currentToken === null || currentTokenExpiration === null){
            throw(new Error("unauthorized"));
        }

        let currentTokenExpirationTime =  Number.parseInt(currentTokenExpiration)
        let accessToken = new AccessToken(currentToken, currentTokenExpirationTime);
        return accessToken;
    }

    public static hasValidToken = () => {
        var currentToken = localStorage.getItem('access_token');
        var tokenExpiration = localStorage.getItem('access_token_expiration_time');

        if(currentToken === null || tokenExpiration === null){
            return false;
        }
        var tokenExpirationTime =  Number.parseInt(tokenExpiration)
        var currentTime = Date.now();
        if(tokenExpirationTime > currentTime){
            return true;
        }
        return false;
    }

    public static setAccessToken = (access_token: string, expires_in: number) => {
        var token_expiration_time = Date.now() + (expires_in * 1000) - 15000;
        localStorage.setItem('access_token', 'Bearer ' + access_token);
        localStorage.setItem('access_token_expiration_time', token_expiration_time.toString());
    }

    public static deleteAccessToken = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_expiration_time');
    }
}