import { ReactNode, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import { userStore } from "../contexts/User/UserHandler"
import CustomerAccountsService from "../services/CustomerAccounts/CustomerAccountsService";
import { CustomerAccountDto } from "../services/CvdbContracts/Api";

const SelectAccount = (props:any) => {

    const {show} = props;
    const [selectedAccount, setSelectedAccount] = useState<CustomerAccountDto>();
    const [options, setOptions] = useState<Array<ReactNode>>();
    const [accounts, setAccounts] = useState(Array<CustomerAccountDto>);

    const doSelectAccount = userStore((state) => state.setCustomer);
    const setVisible = userStore((state) => state.setMustSelectCustomer);
    const isAuthenticated = userStore((state) => state.isAuthenticated)
    const currentCustomerId = userStore((state) => state.customerId)

    useEffect(() => {
        if(isAuthenticated && show){
            CustomerAccountsService.getCustomerAccounts().then(accounts => {
                let theAccounts = accounts as Array<CustomerAccountDto>;
                let options = theAccounts.map(ca => <option key={ca.id} value={ca.id} selected={currentCustomerId === ca.id}>{ca.name}</option>);
                setSelectedAccount(theAccounts[0])
                setAccounts(theAccounts)
                setOptions(options)
            }
    
        );}
    },[show, currentCustomerId, isAuthenticated])

    const onChange = (e: any) => {
        let account = accounts.find( a => a.id === e.target.value);
        setSelectedAccount(account);
    }

    const doHandleSelect = (e: any) =>{
        if(selectedAccount){
          doSelectAccount(selectedAccount.id, selectedAccount.name ?? '');
          setVisible(false);
        }
    }
  
    return (
      <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onChange = {onChange}
      >
        <Modal.Header closeButton onClick={() => setVisible(false)}>
          <Modal.Title>Välj kundkonto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Select aria-label="Välj kund">
            {options}
        </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={doHandleSelect}>Välj kund</Button>
        </Modal.Footer>
      </Modal>
    </>
    )
  }
  
  export default SelectAccount
  