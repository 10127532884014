import Moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { userStore } from '../../contexts/User/UserHandler'
import ApiClientAccessService from '../../services/ApiClientAccess/ApiClientAccessService'
import { ApiClientAuthorizationDto, CreateApiClientAuthorizationRequest } from '../../services/CvdbContracts/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ApiKeys = () => {
  // page content

  const [apiKeys, setApiKeys] = useState<Array<JSX.Element>>([]);
  const [newKey, setNewKey] = useState<CreateApiClientAuthorizationRequest>({} as CreateApiClientAuthorizationRequest);
  const [showNewKeyModal, setShowNewKeyModal] = useState<boolean>(false);

  const customerId = userStore((state) => state.customerId);

  const loadApiKeys = useCallback(() => {
    ApiClientAccessService.getApiClientAuthorizations(customerId).then(apiKeys => {
      let keys = (apiKeys as Array<ApiClientAuthorizationDto>).map(k =>
        <tr key={k.id}>
          <td>{k.clientName}</td>
          <td>{k.clientDescription}</td>
          <td>{k.apiKey}<CopyToClipboard text={k.apiKey ?? ''}>
            <FontAwesomeIcon icon="copy" style={{marginLeft: 10, cursor: 'pointer'}} title="Kopiera nyckel" />
          </CopyToClipboard>
          </td>
          <td>{Moment(k.createdTime).format('YYYY-MM-DD hh:mm')}</td>
        </tr>);

      setApiKeys(keys)
    })
  }, [customerId]);


  useEffect(() => {
    if (customerId !== '') {
      loadApiKeys();
    }
  }, [loadApiKeys, customerId]);

  const createNewKey = async () => {
    await ApiClientAccessService.createApiClientAuthorizations(newKey, customerId);
    loadApiKeys();
    setShowNewKeyModal(false);
  };

  return (
    <div>
      <h3>Api nycklar</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Namn</th>
            <th>Beskrivning</th>
            <th>Nyckel</th>
            <th>Skapad</th>
          </tr>
        </thead>
        <tbody>
          {apiKeys}
        </tbody>
      </Table>

      <Button onClick={() => setShowNewKeyModal(true)}>Skapa API nyckel</Button>


      <Modal show={showNewKeyModal}>
        <Modal.Header closeButton onClick={() => setShowNewKeyModal(false)}>
          <Modal.Title>Ny API nyckel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Namn</Form.Label>
              <Form.Control required placeholder="Namn på API nyckel" onChange={e => setNewKey({ ...newKey, clientName: e.target.value ?? '' })} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
              <Form.Label>Beskrivning</Form.Label>
              <Form.Control type="text" placeholder="Beskrivning" onChange={e => setNewKey({ ...newKey, clientDescription: e.target.value })} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => createNewKey()}>Skapa</Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default ApiKeys;